$blue: #5975d9;
$dark-blue: #262932;
$lightblue: #6dc7dc;
$red: #e45c41;
$orange: #face4f;
$yellow: #face4f;
$green: #26bc47;
$teal: #009190;
$cyan: #009469;

$light: #f8f8fb;
$white-20: rgba(255, 255, 255, 0.2);
$white: #ffffff;
$dark-secondary: #2e3547;
$dark-accent: #121827;
$black: #222222;
$grey: #848484;
$light-grey: #e5e5e5;
