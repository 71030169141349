.header{
	background: #FBFBFF;
	box-shadow: 0px 0px 15px 4px rgba($color: #000000, $alpha: 0.07);
	padding: 1rem 0.5rem;

	h1{
		margin-bottom: 0.2rem;
	}

	span{
		color: $grey;
		font-size: 0.875rem;
	}
}

.nav-tabs {
	border-bottom: 0 !important;
	.nav-item {
		padding: 5px;
		a {
			color: black;
			text-decoration: none;
			border:0;
			cursor: pointer;
			padding: 0 1rem;
			margin-bottom: 10px;

			&.active{
				border: 0;
				background: transparent !important;
				border-bottom: 2px solid $blue;
			}

			&:hover{
				border:0;
				border-bottom: 2px solid $blue;
			}
		}
	}
}

.table-header{
	input{
		width: 33%;	
	}

	button{
		a{
			color: #fff;
			
			&:hover{
				color: #fff;
			}
		}
	}
}

table.table-grid {
	font-size: 0.875rem;
	color: $black;
	
	th {
		font-weight: 400;
	}
	
	tbody {
		border-top: 0 !important;
	}

	thead > tr > th, td {
		border-top: 0;
		border-bottom: 1px solid $light-grey;
		vertical-align: middle;
		button {
			border: 1px solid #000;
			color: #000;

			&:hover{
				border: 1px solid #000;
			}
		}
	}

	.progress{
		height: 10px;
		margin-top: 8px;
	}

}

a:not([href]).back-link{
	cursor: pointer;
	color: $blue;
	svg{
		margin-right: 10px;
	}
}

.modal-dialog{
	.modal-content
	{
		background: $light;
		


	}
}

.hero {
	img {
		width: 70px;
		height: 70px;
		border-radius: 200px;
	}

	.reporter-section {
		svg{
			margin-left: 10px;
		}
	}
}

.incident-list{
	a {
		svg{
			margin-left: 10px;
		}
	}
}

.btn {
	svg {
		margin-left: 10px;
	}
}

.incident-form{
	.category-button{
		border: 1px solid #000;
		color: #000;
		height: 191px;
		width: 100%;
		margin-right: 10px;
		border-radius: 8px;
		background: #fff;
		border: 1px solid #c4c4c4;
		cursor: pointer;
		text-align: center;
		padding-top: 20px;

		img{
			height: 30%;
			margin-top: 20px;
		}

		p{
			margin-top: 10px;
		}

		&.selected{
			border: 3px solid $blue !important;
		}

		&:hover{
			border: 3px solid $blue !important;
			box-shadow: 0 5px 15px rgba(0,0,0,0.3);
		}
	}
}

.incident-view{
	p.label{
		color: $black;
		font-size: 0.875rem;
		margin-bottom: 0;
	}
	p.value{
		font-size: 1.125rem;
	}
	padding-top: 1rem;
}

.incident-search{
	.custom-switch{
		margin-left: 10px;
		margin-top: 7px;
		margin-bottom: 10px;
		@media screen and (min-width: 576px) {
			margin-top: 0px;
			margin-bottom: 0px;
		}
	}
}


.incident-data-filters {
	border-bottom: 1px solid $light-grey;
	button{
		height: 35px;
		width: 100%;

		&:not(:last-child){
			margin-right: 0.625rem;	
		}
		
		&:hover{
			border: 1px solid $dark-accent;
		}
	
		padding: 3px 10px 3px 10px !important;
	}

	.form-group{
		width: 100%;
		&:not(:last-child){
			margin-right: 0.625rem;	
		}
	}

	.buttons{
		margin-bottom: 10px;
		@media screen and (min-width: 992px) {
			margin-bottom: 0px;
		}
	}
}

.infocard {
	background: #fafafc;

	label {
		color:$grey;
		font-size: 0.875rem;
	}
	
	.small-value {
		color:$dark-accent;
		font-size: 0.875rem;
	}
	
	.medium-value {
		color:$dark-accent;
		font-size: 1.125rem;
	}

	.large-value {
		color:$dark-accent;
		font-size: 1.375rem;
		font-weight: bold;
	}
}


.btn {
	padding: 3px 30px 3px 30px !important;
}
.btn-form-input {
	padding: 6px 30px 6px 30px !important;
}
.badge {
	border-radius: 0.5rem !important;
}

.radios  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff !important;
    border-color: $blue !important;
    background-color: $blue !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff !important;
    border-color: $green !important;
    background-color: $green !important;
}

.btn-white{
	border: 1px solid $grey !important;
	color: $grey !important;
	font-size: 0.875rem !important;

	&:hover{
		border: 1px solid #000 !important;
	}
}

.form-control{
	border: 1px solid $grey !important;
	font-size: 0.875rem !important;
}

.pager{
	.btn{
		padding:0.2rem !important;
		border-radius: 0 !important;
		color: $grey !important;
		border-color: $grey !important;

		&.current{
			border-color: $blue !important;
		}
	}
}

.buildnumber{
	color: $light;
}

.large-link,.large-link:hover, .large-link:visited, .large-link:active {
	text-decoration: none;
}

table.table-discreet th {
	color: $grey;
	font-weight: normal !important;
}
table.table-discreet tbody {
	border-top: 1px solid $grey !important;
}
.small-label {
	font-size: 0.7em;
	color: $grey;
}