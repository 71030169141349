.App {
	display: flex;
	width: 100%;
	align-items: stretch;
}
.sidebar {
	min-width: 250px;
	max-width: 250px;
	color: #fff;
	margin-left: -250px;
	transition: all 0.5s;
	position: fixed;
	height: 100%;

	@media only screen and (min-width: 576px) {
		margin-left: 0;
	}
}

.sidebar.is-open {
	margin-left: 0;
	transition: 0.5s;
}

.sidebar-header h3 {
	color: #fff;
	padding: 1em;
}

.sidebar ul p {
	color: #fff;
	padding: 10px;
}

.menu-open {
	background: #6d7fcc;
}
.nav-item {
	a {
		color: rgba(255, 255, 255, 0.6);
		&.active {
			color: #fff;
			border-left: 3px solid #6d7fcc;
		}
		&:hover {
			color: #fff;
		}
	}
}

.items-menu {
	color: #fff;
	background: #6d7fcc;
}

.sidebar-header > span {
	position: relative;
	float: right;
	margin: 0.5em;
	font-size: 2rem;
	cursor: pointer;
	display: none;
}
.side-menu {
	height: calc(100vh - 130px);
	overflow-y: hidden;
}

.side-menu::-webkit-scrollbar {
	width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
	background: #5466b3;
	border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
	background: #3a4c99;
}

.content {
	padding: 0px;
	margin-left: 0;
	height: 100vh;
	width: 100%;
	transition: all 0.5s;

	@media only screen and (min-width: 576px) {
		margin-left: 250px;
	}
}

.content.is-open {
	margin-left: 250px;
}

@media only screen and (min-width: 576px) {
	.content{
		.menu-toggle{
			display: none;
		}
	}
}

@media only screen and (max-width: 575px) {
	.content.is-open {
		margin-left: 0px;
	}
	.sidebar {
		z-index: 2000;
	}
	.sidebar.is-open {
		min-width: 100%;
		max-width: 100%;
		margin-left: 0;
		transition: all 0.5s, height 0s;
	}

	.sidebar.is-open > .sidebar-header span {
		display: unset;
	}
}
